import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

const Seo = ({ seo = {} }) => {
  const fullSeo = { ...seo }
  const links = [
    {
      rel: 'canonical',
      content: fullSeo.metaUrl || 'https://www.mlytics.com/'
    }
  ]
  const getMetaTags = () => {
    const tags = []
    const siteName = 'Mlytics'

    fullSeo.metaTitle = `${fullSeo.metaTitle}`

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: 'og:title',
          content: fullSeo.metaTitle
        },
        {
          name: 'twitter:title',
          content: fullSeo.metaTitle
        }
      )
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: 'description',
          content: fullSeo.metaDescription
        },
        {
          property: 'og:description',
          content: fullSeo.metaDescription
        },
        {
          name: 'twitter:description',
          content: fullSeo.metaDescription
        }
      )
    }
    if (fullSeo.metaUrl) {
      tags.push({
        property: 'og:url',
        content: fullSeo.metaUrl
      })
    }
    if (fullSeo.shareImage) {
      const { imageUrl = '', imageWidth = 500, imageHeight = 90, imageType = 'image/png' } = fullSeo.shareImage

      tags.push(
        {
          property: 'og:image',
          content: imageUrl
        },
        {
          property: 'og:image:width',
          content: imageWidth
        },
        {
          property: 'og:image:height',
          content: imageHeight
        },
        {
          property: 'og:image:type',
          content: imageType
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          name: 'twitter:image',
          content: imageUrl
        }
      )
    }
    tags.push(
      {
        property: 'og:site_name',
        content: siteName
      },
      {
        property: 'article:publisher',
        content: 'https://facebook.com/mlytics'
      },
      {
        property: 'og:type',
        content: 'website'
      },
      {
        property: 'og:locale',
        content: 'en_US'
      },
      {
        name: 'twitter:site',
        content: '@mlyticsHQ'
      }
    )

    return tags
  }
  const metaTags = getMetaTags()

  return <Helmet title={fullSeo.metaTitle} link={links} meta={metaTags} />
}

Seo.propTypes = {
  seo: PropTypes.object
}

export default Seo
