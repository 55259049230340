export const Features = [
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Immediate-access.png',
    title: 'pulse.section3.card1.title',
    content: 'pulse.section3.card1.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Stay-informed.png',
    title: 'pulse.section3.card2.title',
    content: 'pulse.section3.card2.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Full-transparency-everywhere.png',
    title: 'pulse.section3.card3.title',
    content: 'pulse.section3.card3.content'
  }
]
